export const GET_BATCH_REQUEST = 'GET_BATCH_REQUEST';
export const GET_BATCH_SUCCESS = 'GET_BATCH_SUCCESS';
export const GET_BATCH_FAILURE = 'GET_BATCH_FAILURE';

export const ADD_BATCH_REQUEST = 'ADD_BATCH_REQUEST';
export const ADD_BATCH_SUCCESS = 'ADD_BATCH_SUCCESS';
export const ADD_BATCH_FAILURE = 'ADD_BATCH_FAILURE';

export const EDIT_BATCH_REQUEST = 'EDIT_BATCH_REQUEST';
export const EDIT_BATCH_SUCCESS = 'EDIT_BATCH_SUCCESS';
export const EDIT_BATCH_FAILURE = 'EDIT_BATCH_FAILURE';
