// src/constants/labConstants.js

export const GET_LAB_REQUEST = "GET_LAB_REQUEST";
export const GET_LAB_SUCCESS = "GET_LAB_SUCCESS";
export const GET_LAB_FAILURE = "GET_LAB_FAILURE";

export const ADD_LAB_REQUEST = "ADD_LAB_REQUEST";
export const ADD_LAB_SUCCESS = "ADD_LAB_SUCCESS";
export const ADD_LAB_FAILURE = "ADD_LAB_FAILURE";

export const CREATE_LAB_REQUEST = "CREATE_LAB_REQUEST";
export const CREATE_LAB_SUCCESS = "CREATE_LAB_SUCCESS";
export const CREATE_LAB_FAILURE = "CREATE_LAB_FAILURE";

export const START_LAB_REQUEST = "START_LAB_REQUEST";
export const START_LAB_SUCCESS = "START_LAB_SUCCESS";
export const START_LAB_FAILURE = "START_LAB_FAILURE";
export const STOP_LAB_SUCCESS = "STOP_LAB_SUCCESS";
export const STOP_LAB_FAILURE = "STOP_LAB_FAILURE";