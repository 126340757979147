export const ADMIN_TOPIC_REQUEST = "ADMIN_TOPIC_REQUEST";
export const ADMIN_TOPIC_SUCCESS = "ADMIN_TOPIC_SUCCESS";
export const ADMIN_TOPIC_FAILURE = "ADMIN_TOPIC_FAILURE";

export const ADD_TOPIC_REQUEST = "ADD_TOPIC_REQUEST";
export const ADD_TOPIC_SUCCESS = "ADD_TOPIC_SUCCESS";
export const ADD_TOPIC_FAILURE = "ADD_TOPIC_FAILURE";

export const EDIT_TOPIC_REQUEST = "EDIT_TOPIC_REQUEST";
export const EDIT_TOPIC_SUCCESS = "EDIT_TOPIC_SUCCESS";
export const EDIT_TOPIC_FAILURE = "EDIT_TOPIC_FAILURE";

export const ADMIN_GET_CONTENT_REQUEST = "ADMIN_GET_CONTENT_REQUEST";
export const ADMIN_GET_CONTENT_SUCCESS = "ADMIN_GET_CONTENT_SUCCESS";
export const ADMIN_GET_CONTENT_FAILURE = "ADMIN_GET_CONTENT_FAILURE";

export const ADMIN_UPDATE_CONTENT_REQUEST = "ADMIN_UPDATE_CONTENT_REQUEST";
export const ADMIN_UPDATE_CONTENT_SUCCESS = "ADMIN_UPDATE_CONTENT_SUCCESS";
export const ADMIN_UPDATE_CONTENT_FAILURE = "ADMIN_UPDATE_CONTENT_FAILURE";


// src/constants/topicConstants.js
export const TOPIC_LIST_REQUEST = 'TOPIC_LIST_REQUEST'
export const TOPIC_LIST_SUCCESS = 'TOPIC_LIST_SUCCESS'
export const TOPIC_LIST_FAIL = 'TOPIC_LIST_FAIL'

export const SUBTOPIC_ADD_REQUEST = 'SUBTOPIC_ADD_REQUEST'
export const SUBTOPIC_ADD_SUCCESS = 'SUBTOPIC_ADD_SUCCESS'
export const SUBTOPIC_ADD_FAILURE = 'SUBTOPIC_ADD_FAILURE'


export const SUBTOPIC_LIST_REQUEST = 'SUBTOPIC_LIST_REQUEST'
export const SUBTOPIC_LIST_SUCCESS = 'SUBTOPIC_LIST_SUCCESS'
export const SUBTOPIC_LIST_FAILURE = 'SUBTOPIC_LIST_FAILURE'


export const SUBTOPIC_DELETE_REQUEST = 'SUBTOPIC_DELETE_REQUEST';
export const SUBTOPIC_DELETE_SUCCESS = 'SUBTOPIC_DELETE_SUCCESS';
export const SUBTOPIC_DELETE_FAILURE = 'SUBTOPIC_DELETE_FAILURE';
