import React from 'react'
import Education from './education/EducationComponent';
import Skills from "../Placement/skills/Skills"


const Placement = () => {
  return (
    <>
      <Education />
      {/* <Skills /> */}
    </>
  )
}

export default Placement