export const GET_MODULE_REQUEST = "GET_MODULE_REQUEST";
export const GET_MODULE_SUCCESS = "GET_MODULE_SUCCESS";
export const GET_MODULE_FAILURE = "GET_MODULE_FAILURE";


export const GET_SPECIFIC_MODULE_REQUEST = "GET_SPECIFIC_MODULE_REQUEST";
export const GET_SPECIFIC_MODULE_SUCCESS = "GET_SPECIFIC_MODULE_SUCCESS";
export const GET_SPECIFIC_MODULE_FAILURE = "GET_SPECIFIC_MODULE_FAILURE";


export const EDIT_MODULE_REQUEST = "EDIT_MODULE_REQUEST";
export const EDIT_MODULE_SUCCESS = "EDIT_MODULE_SUCCESS";
export const EDIT_MODULE_FAILURE = "EDIT_MODULE_FAILURE";


export const ADD_MODULE_REQUEST = "ADD_MODULE_REQUEST";
export const ADD_MODULE_SUCCESS = "ADD_MODULE_SUCCESS";
export const ADD_MODULE_FAILURE = "ADD_MODULE_FAILURE";


export const ADD_BATCH_REQUEST = "ADD_BATCH_REQUEST";
export const ADD_BATCH_SUCCESS = "ADD_BATCH_SUCCESS";
export const ADD_BATCH_FAILURE = "ADD_BATCH_FAILURE";


export const GET_BATCH_REQUEST = "GET_BATCH_REQUEST";
export const GET_BATCH_SUCCESS = "GET_BATCH_SUCCESS";
export const GET_BATCH_FAILURE = "GET_BATCH_FAILURE";


export const GET_MODULE_DETAILS_REQUEST = 'GET_MODULE_DETAILS_REQUEST';
export const GET_MODULE_DETAILS_SUCCESS = 'GET_MODULE_DETAILS_SUCCESS';
export const GET_MODULE_DETAILS_FAILURE = 'GET_MODULE_DETAILS_FAILURE';


export const GET_MODULE_STATS_REQUEST = 'GET_MODULE_STATS_REQUEST';
export const GET_MODULE_STATS_SUCCESS = 'GET_MODULE_STATS_SUCCESS';
export const GET_MODULE_STATS_FAILURE = 'GET_MODULE_STATS_FAILURE';










