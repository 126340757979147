export const USER_REFRESH_REQUEST = 'USER_REFRESH_REQUEST'
export const USER_REFRESH_SUCCESS = 'USER_REFRESH_SUCCESS'
export const USER_REFRESH_FAILURE = 'USER_REFRESH_FAILURE'
export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAILURE = 'USER_SIGNIN_FAILURE'
export const USER_SIGNOUT_SUCCESS = 'USER_SIGNOUT_SUCCESS'
export const USER_SIGNOUT_FAILURE = 'USER_SIGNOUT_FAILURE'
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const LEADERBOARD_REQUEST = 'LEADERBOARD_REQUEST'
export const LEADERBOARD_SUCCESS = 'LEADERBOARD_SUCCESS'
export const LEADERBOARD_FAILURE = 'LEADERBOARD_FAILURE'

//admin user constants
export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const ALL_USERS_FAILURE = 'ALL_USERS_FAILURE'

export const ALL_TEACHERS_REQUEST = 'ALL_TEACHERS_REQUEST'
export const ALL_TEACHERS_SUCCESS = 'ALL_TEACHERS_SUCCESS'
export const ALL_TEACHERS_FAILURE = 'ALL_TEACHERS_FAILURE'

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE'
