export const ADD_ASSESSMENT_REQUEST = 'ADD_ASSESSMENT_REQUEST'
export const ADD_ASSESSMENT_SUCCESS = 'ADD_ASSESSMENT_SUCCESS'
export const ADD_ASSESSMENT_FAILURE = 'ADD_ASSESSMENT_FAILURE'


export const GET_ASSESSMENT_QUESTIONS_REQUEST = 'GET_ASSESSMENT_QUESTIONS_REQUEST';
export const GET_ASSESSMENT_QUESTIONS_SUCCESS = 'GET_ASSESSMENT_QUESTIONS_SUCCESS';
export const GET_ASSESSMENT_QUESTIONS_FAILURE = 'GET_ASSESSMENT_QUESTIONS_FAILURE';


export const ASSESSMENT_REVIEW_REQUEST = 'ASSESSMENT_REVIEW_REQUEST';
export const ASSESSMENT_REVIEW_SUCCESS = 'ASSESSMENT_REVIEW_SUCCESS';
export const ASSESSMENT_REVIEW_FAILURE = 'ASSESSMENT_REVIEW_FAILURE';


export const GET_ASSESSMENTS_TO_REVIEW_REQUEST = 'GET_ASSESSMENTS_TO_REVIEW_REQUEST';
export const GET_ASSESSMENTS_TO_REVIEW_SUCCESS = 'GET_ASSESSMENTS_TO_REVIEW_SUCCESS';
export const GET_ASSESSMENTS_TO_REVIEW_FAILURE = 'GET_ASSESSMENTS_TO_REVIEW_FAILURE';


export const GET_ALL_ASSESSMENTS_REQUEST = 'GET_ALL_ASSESSMENTS_REQUEST';
export const GET_ALL_ASSESSMENTS_SUCCESS = 'GET_ALL_ASSESSMENTS_SUCCESS';
export const GET_ALL_ASSESSMENTS_FAILURE = 'GET_ALL_ASSESSMENTS_FAILURE';
