import React from 'react'
import ButtonAppBar from './AppBar'
import ModulesDataRender from './ModulesDataRender'



const Progress = () => {
  return (
    <>
      <ButtonAppBar />
      <ModulesDataRender />
    </>
  )
}

export default Progress

